import React from 'react';
import Produto from '~/pages/Produto';
import { ProdutoContextProvider } from './ProdutoContext';

const ProdutoContent: React.FC = () => {
  return (
    <ProdutoContextProvider>
      <Produto />
    </ProdutoContextProvider>
  );
};
export default ProdutoContent;
